











































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  addLoraDevice,
  getDevice,
  getLoraDevice,
  getLoraDeviceList,
  getMeasurements,
  getAvailableHistoryMeasurements,
  getMeterTemplates,
  getUpdateProgress,
  removeLoraDevice,
  updateDeviceProperty,
  updateLoraDeviceProperty
} from '../../api/user';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import {
  DeviceType,
  DeviceShadow,
  UpdateStatus,
  MeterTemplate,
  Measurement
} from '../../typings';
import {
  mdiWifiPlus,
  mdiRefresh,
  mdiPencil,
  mdiChevronDown,
  mdiClose,
  mdiMagnify,
  mdiQrcodeScan,
  mdiFormTextbox,
  mdiFactory,
  mdiGhost,
  mdiPaperRoll,
  mdiCounter,
  mdiChartLine
} from '@mdi/js';
import type { ValidationRule } from '../../typings';
import MeterConfigComponent from '../../components/MeterConfigComponent.vue';
import MeasurementChartComponent from '../../components/MeasurementChartComponent.vue';
import _ from 'lodash';
import type { JsonObject, JsonValue } from 'type-fest';
import Auth from '@aws-amplify/auth';
import { noop } from 'vue-class-component/lib/util';
import { QrcodeStream } from 'vue-qrcode-reader';
import { Hub } from '@aws-amplify/core';
import { SettingsModule } from '../../plugins/store';
import { getModule } from 'vuex-module-decorators';

interface TableRow {
  uid: string;
  path: string;
  reported: { value: unknown; timestamp: number | undefined };
  desired: { value: unknown; timestamp: number | undefined };
}

const settingsStore: SettingsModule = getModule(SettingsModule);

@Component({
  components: {
    MeterConfigComponent,
    MeasurementChartComponent,
    QrcodeStream
  }
})
export default class DeviceTableView extends Vue {
  private readonly DeviceType: typeof DeviceType = DeviceType;
  private readonly mdiRefresh: string = mdiRefresh;
  private readonly mdiWifiPlus: string = mdiWifiPlus;
  private readonly mdiPencil: string = mdiPencil;
  private readonly mdiChevronDown: string = mdiChevronDown;
  private readonly mdiClose: string = mdiClose;
  private readonly mdiMagnify: string = mdiMagnify;
  private readonly mdiFactory: string = mdiFactory;
  private readonly mdiGhost: string = mdiGhost;
  private readonly mdiPaperRoll: string = mdiPaperRoll;
  private readonly mdiQrcodeScan: string = mdiQrcodeScan;
  private readonly mdiFormTextbox: string = mdiFormTextbox;
  private readonly mdiCounter: string = mdiCounter;
  private readonly mdiChartLine: string = mdiChartLine;
  private readonly noop: typeof noop = noop;
  private readonly requiredRule: ValidationRule = (
    v: unknown
  ): true | string => !!v || '$vuetify.device.REQUIRED';
  private readonly intervalRule: ValidationRule = (
    v: unknown
  ): true | string => {
    const numVal: number =
      typeof v === 'string'
        ? parseFloat((v as string).replace(',', '.'))
        : (v as number);
    return (
      !v ||
      (!isNaN(v as number) && numVal >= 37.5 && numVal <= 600) ||
      '$vuetify.device.INVALID'
    );
  };
  private readonly deviceTableHeader: {
    text: string;
    value: string;
    groupable: true;
  } = {
    text: '',
    value: 'uid',
    groupable: true
  };
  private readonly loggerLevels: string[] = [
    'error',
    'warn',
    'info',
    'debug',
    'verbose'
  ];

  private hasWebcam: boolean = false;
  private scannerError: boolean = false;
  private scannerShow: boolean = false;
  private scannerLoading: boolean = false;
  private dialogShow: boolean = false;
  private dialogValid: boolean = false;
  private dialogUid: string = '';
  private dialogVerification: string = '';
  private dialogMeterUid: string = '';
  private dialogMeterValid: boolean = false;
  private dialogBasicConfig: string = '';
  private dialogDataConfig: string | null = null;
  private dialogRevolutionsPerKWH: number | '' = '';
  private update: UpdateStatus | null = null;
  private liveInterval: ReturnType<typeof setInterval> | null = null;
  private updateInterval: ReturnType<typeof setInterval> | null = null;
  private loading: boolean = true;
  private userGroups: string[] = [];
  private removeUid: string = '';
  private loraShadows: Record<string, DeviceShadow> = {};
  private shadow: DeviceShadow | null = null;
  private tableItems: Array<TableRow> = [];
  private loraTableItems: Array<TableRow> = [];
  private templateItems: MeterTemplate[] = [];
  private measurements: Record<string, Measurement> = {};
  private availableHistoryMeasurements: Record<string, string[]> = {};
  private dialogBasicMeasurement: {
    uid: string;
    measurement: string;
  } | null = null;

  private get tableHeaders(): Array<{
    text: string;
    value: string;
    sortable: boolean;
    groupable: false;
  }> {
    return [
      {
        text: this.$vuetify.lang.t('$vuetify.device.PROPERTY'),
        value: 'path',
        sortable: true,
        groupable: false
      },
      {
        text: this.$vuetify.lang.t('$vuetify.device.REPORTED'),
        value: 'reported',
        sortable: false,
        groupable: false
      },
      {
        text: this.$vuetify.lang.t('$vuetify.device.DESIRED'),
        value: 'desired',
        sortable: false,
        groupable: false
      }
    ];
  }

  private get dialogMeterBasic(): string {
    if (!this.dialogBasicConfig) {
      if (![...this.loraUIDs, this.uid].includes(this.dialogMeterUid)) {
        this.dialogBasicConfig = '';
      } else if (this.dialogMeterUid === this.uid) {
        this.dialogBasicConfig = (
          this.shadow?.state?.desired?.meter_configuration
            ?.basic_config_data ||
          this.shadow?.state?.reported?.meter_configuration
            ?.basic_config_data ||
          ''
        ).toUpperCase();
      } else {
        this.dialogBasicConfig = (
          this.loraShadows[this.dialogMeterUid]?.state?.desired
            ?.meter_configuration?.basic_config_data ||
          this.loraShadows[this.dialogMeterUid]?.state?.reported
            ?.meter_configuration?.basic_config_data ||
          ''
        ).toUpperCase();
      }
    }
    return this.dialogBasicConfig;
  }

  private get dialogMeterData(): string {
    if (this.dialogDataConfig === null) {
      if (![...this.loraUIDs, this.uid].includes(this.dialogMeterUid)) {
        return '';
      } else if (this.dialogMeterUid === this.uid) {
        this.dialogDataConfig = (
          this.shadow?.state?.desired?.meter_configuration
            ?.data_config_data ||
          this.shadow?.state?.reported?.meter_configuration
            ?.data_config_data ||
          ''
        ).toUpperCase();
      } else {
        this.dialogDataConfig = (
          this.loraShadows[this.dialogMeterUid]?.state?.desired
            ?.meter_configuration?.data_config_data ||
          this.loraShadows[this.dialogMeterUid]?.state?.reported
            ?.meter_configuration?.data_config_data ||
          ''
        ).toUpperCase();
      }
    }
    return this.dialogDataConfig;
  }

  private get dialogRPKWH(): number {
    if (this.dialogRevolutionsPerKWH !== '') {
      return this.dialogRevolutionsPerKWH;
    }
    let value: number | string;
    if (![...this.loraUIDs, this.uid].includes(this.dialogMeterUid)) {
      value = '';
    } else if (this.dialogMeterUid === this.uid) {
      value = (this.shadow?.state?.desired?.revolutions_per_kilo_watt_hour ||
        this.shadow?.state?.reported?.revolutions_per_kilo_watt_hour ||
        '') as number | string;
    } else {
      value = (this.loraShadows[this.dialogMeterUid]?.state?.desired
        ?.revolutions_per_kilo_watt_hour ||
        this.loraShadows[this.dialogMeterUid]?.state?.reported
          ?.revolutions_per_kilo_watt_hour ||
        '') as number | string;
    }
    if (value === '') {
      this.dialogRevolutionsPerKWH = value = 75;
    } else if (typeof value === 'string') {
      value = parseFloat(value);
    }
    return value;
  }

  private get downloadProgress(): number {
    const matched: string[] | null = (
      this.update?.downloadProgress || ''
    ).match(/(\d+)\/(\d+)/);
    if (!matched) {
      return 100;
    }
    return (parseInt(matched[1], 10) / parseInt(matched[2], 10)) * 100;
  }

  private get dataItems(): Array<{ value: string; text: string }> {
    return Object.entries(this.measurements)
      .filter(([key]: [string, Measurement]): boolean =>
        /^[A-F0-9]{10}$/.test(key)
      )
      .map(
        ([value, measurement]: [string, Measurement]): {
          value: string;
          text: string;
        } => ({
          value,
          text:
            measurement.description[settingsStore.actualLocale] ||
            measurement.description['en']
        })
      );
  }

  private get isLive(): boolean {
    return this.liveInterval !== null;
  }

  private set isLive(isLive: boolean) {
    if (isLive && this.liveInterval === null) {
      this.liveInterval = setInterval(this.loadLive, 10000);
    } else if (!isLive && this.liveInterval !== null) {
      clearInterval(this.liveInterval);
      this.liveInterval = null;
    }
  }

  private get loraUIDs(): string[] {
    return Object.keys(this.loraShadows);
  }

  @Prop({
    type: String,
    required: true,
    validator: (value: DeviceType): boolean =>
      Object.values(DeviceType).includes(value)
  })
  public deviceType!: DeviceType;

  @Prop({
    type: String,
    required: true,
    validator: (value: string): boolean => !!value
  })
  public uid!: string;

  @Prop({ type: [String, Boolean], default: false })
  private readonly searchTable!: string | false;

  @Watch('shadow.state', {
    deep: true
  })
  private onStateChange(): void {
    this.tableItems = _.uniq([
      ...this.getLeaves(this.shadow?.state?.reported || {}),
      ...this.getLeaves(this.shadow?.state?.desired || {})
    ]).map((path: string): TableRow => {
      return {
        uid: this.uid,
        path,
        reported: {
          value: _.get(this.shadow?.state?.reported, path, ''),
          timestamp: _.get(
            this.shadow?.metadata?.reported,
            `${path}.timestamp`
          ) as number | undefined
        },
        desired: {
          value: _.get(this.shadow?.state?.desired, path, ''),
          timestamp: _.get(
            this.shadow?.metadata?.desired,
            `${path}.timestamp`
          ) as number | undefined
        }
      };
    });
  }

  @Watch('loraShadows', {
    deep: true
  })
  private onLoraShadowsChange(): void {
    this.loraTableItems = Object.entries(this.loraShadows).reduce(
      (
        rows: TableRow[],
        [uid, shadow]: [string, DeviceShadow]
      ): TableRow[] => {
        rows.push(
          ..._.uniq([
            ...this.getLeaves(shadow?.state?.reported || {}),
            ...this.getLeaves(shadow?.state?.desired || {})
          ]).map((path: string): TableRow => {
            return {
              uid,
              path,
              reported: {
                value: _.get(shadow?.state?.reported, path, ''),
                timestamp: _.get(
                  shadow?.metadata?.reported,
                  `${path}.timestamp`
                ) as number | undefined
              },
              desired: {
                value: _.get(shadow?.state?.desired, path, ''),
                timestamp: _.get(
                  shadow?.metadata?.desired,
                  `${path}.timestamp`,
                  ''
                ) as number | undefined
              }
            };
          })
        );
        return rows;
      },
      []
    );
  }

  private async mounted(): Promise<void> {
    this.setHasWebcam();
    this.load();
    this.getAvailableHistoryMeasurements(this.uid);
    this.userGroups =
      (await Auth.currentSession())?.getIdToken()?.payload?.[
        'cognito:groups'
      ] || [];
  }

  private destroyed(): void {
    this.hideUpdate();
    this.isLive = false;
  }

  private validateNumberKeydown(event: KeyboardEvent): void {
    const isForbiddenChar: boolean =
      event.key.length === 1 && !/[\d,.]/.test(event.key);
    const isAllowedModifier: boolean = event.ctrlKey || event.metaKey;
    if (isForbiddenChar && !isAllowedModifier) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private validateNumberPaste(event: ClipboardEvent | DragEvent): void {
    const data: string =
      (event as ClipboardEvent).clipboardData?.getData('text/plain') ||
      (event as DragEvent).dataTransfer?.getData('text/plain') ||
      '';
    if (!/^\d+[,.]?\d+$/.test(data)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private async setHasWebcam(): Promise<void> {
    if (typeof navigator?.mediaDevices?.enumerateDevices === 'function') {
      navigator.mediaDevices
        .enumerateDevices()
        .then(
          (devices: MediaDeviceInfo[]): void =>
            void (this.hasWebcam = devices.some(
              (device: MediaDeviceInfo): boolean =>
                device.kind.includes('video')
            ))
        )
        .catch(noop);
    }
  }

  private getDescription(path?: string): string {
    const name: string = (path || '').split('.').pop() || '';
    return (
      this.measurements[name]?.description?.[settingsStore.actualLocale] ||
      this.measurements[name]?.description?.['en'] ||
      path ||
      ''
    );
  }

  private getUnit(path?: string): string {
    return this.measurements[(path || '').split('.').pop() || '']?.unit || '';
  }

  private getLeaves(tree: JsonObject, rootPath: string = ''): string[] {
    const leaves: string[] = [];
    function walk(branch: JsonObject, basePath: string = ''): void {
      for (const n in branch) {
        if (Object.prototype.hasOwnProperty.call(branch, n)) {
          const newPath: string = basePath ? `${basePath}.${n}` : n;
          if (typeof branch[n] === 'object' || branch[n] instanceof Array) {
            walk(branch[n] as JsonObject, newPath);
          } else {
            leaves.push(newPath);
          }
        }
      }
    }
    walk(tree, rootPath);
    return leaves;
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private showDialog(): void {
    this.dialogShow = true;
    if (
      !this.hasWebcam &&
      typeof navigator?.mediaDevices?.getUserMedia === 'function'
    ) {
      navigator.mediaDevices
        .getUserMedia({
          video: true
        })
        .then(this.setHasWebcam)
        .catch(noop);
    }
  }

  private paintOutline(
    detectedCodes: Array<{ cornerPoints: Array<{ x: number; y: number }> }>,
    ctx: CanvasRenderingContext2D
  ): void {
    for (const detectedCode of detectedCodes) {
      const [firstPoint, ...otherPoints]: Array<{ x: number; y: number }> =
        detectedCode.cornerPoints;

      ctx.strokeStyle = 'green';
      ctx.lineWidth = 4;

      ctx.beginPath();
      ctx.moveTo(firstPoint.x, firstPoint.y);
      for (const { x, y } of otherPoints) {
        ctx.lineTo(x, y);
      }
      ctx.lineTo(firstPoint.x, firstPoint.y);
      ctx.closePath();
      ctx.stroke();
    }
  }

  private paintError(
    detectedCodes: Array<{ cornerPoints: Array<{ x: number; y: number }> }>,
    ctx: CanvasRenderingContext2D
  ): void {
    for (const detectedCode of detectedCodes) {
      const [firstPoint, ...otherPoints]: Array<{ x: number; y: number }> =
        detectedCode.cornerPoints;

      ctx.strokeStyle = 'red';
      ctx.lineWidth = 4;

      ctx.beginPath();
      ctx.moveTo(firstPoint.x, firstPoint.y);
      for (const { x, y } of otherPoints) {
        ctx.lineTo(x, y);
      }
      ctx.lineTo(firstPoint.x, firstPoint.y);
      ctx.lineTo(otherPoints[1].x, otherPoints[1].y);
      ctx.closePath();
      ctx.stroke();
    }
  }

  private decodeQR(text: string): void {
    let json: { uid: string; verification: string };
    try {
      json = JSON.parse(text);
      if (!json || !json.uid || !json.verification) {
        throw json;
      }
    } catch (e) {
      this.scannerError = true;
      return;
    }
    this.scannerError = false;
    setTimeout((): void => {
      this.dialogUid = json.uid;
      this.dialogVerification = json.verification;
      this.scannerShow = false;
    }, 1500);
  }

  private async scannerInit(init: Promise<unknown>): Promise<void> {
    this.scannerLoading = true;
    try {
      await init;
    } catch (e) {
      this.showError(e.message);
      this.scannerShow = false;
    } finally {
      this.scannerLoading = false;
    }
  }

  private checkUpdate(): void {
    getUpdateProgress(this.deviceType, this.uid)
      .then((update: UpdateStatus): void => {
        this.update = update;
        if (!this.updateInterval) {
          this.updateInterval = setInterval(this.checkUpdate, 5000);
        }
      })
      .catch(this.hideUpdate);
  }

  private hideUpdate(): void {
    this.update = null;
    if (this.updateInterval) {
      clearInterval(this.updateInterval);
      this.updateInterval = null;
    }
  }

  private load(): void {
    this.loading = true;
    this.checkUpdate();
    getDevice(this.deviceType, this.uid)
      .then((value: DeviceShadow): Promise<string[]> => {
        let version: string | undefined = value?.state?.reported
          ?.esp_software_version as string | undefined;
        if (
          !version ||
          this.shadow?.state?.reported?.esp_software_version !== version
        ) {
          version =
            typeof version === 'string'
              ? version.replace(/^V/i, '')
              : undefined;
          this.getMeasurements(version);
          this.getMeterTemplates(version);
        }
        this.shadow = value;
        if (this.deviceType === DeviceType.GATEWAY) {
          return getLoraDeviceList(this.uid);
        }
        return Promise.resolve([]);
      })
      .then(
        (uids: string[]): Promise<void[]> =>
          Promise.all([
            ...uids.map(
              (uid: string): Promise<void> => this.getLoraDevice(uid)
            ),
            ...uids.map(
              (uid: string): Promise<void> =>
                this.getAvailableHistoryMeasurements(uid)
            )
          ])
      )
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private loadLive(): void {
    this.loading = true;
    Promise.all([
      getDevice(this.deviceType, this.uid).then(
        (value: DeviceShadow): void => void (this.shadow = value)
      ),
      ...(this.deviceType === DeviceType.GATEWAY
        ? this.loraUIDs.map(
            (uid: string): Promise<void> => this.getLoraDevice(uid)
          )
        : [])
    ])
      .catch((error: Error): void => {
        this.isLive = false;
        this.showError(error.message);
      })
      .finally((): void => void (this.loading = false));
  }

  private getDevice(): void {
    this.loading = true;
    getDevice(this.deviceType, this.uid)
      .then((value: DeviceShadow): void => void (this.shadow = value))
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private getLoraDevice(uid: string): Promise<void> {
    this.loading = true;
    return getLoraDevice(this.uid, uid)
      .then(
        (value: DeviceShadow): void =>
          void this.$set(this.loraShadows, uid, value)
      )
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private async getAvailableHistoryMeasurements(uid: string): Promise<void> {
    await getAvailableHistoryMeasurements(uid)
      .then(
        (measurements: string[]): void =>
          void (this.availableHistoryMeasurements[uid] = measurements)
      )
      .catch(noop);
  }

  private getMeasurements(version?: string): Promise<void> {
    return getMeasurements(settingsStore.ignoreVersion ? undefined : version)
      .then(
        (value: Record<string, Measurement>): void =>
          void (this.measurements = value)
      )
      .catch((error: Error): void => this.showError(error.message));
  }

  private getMeterTemplates(version?: string): Promise<void> {
    return getMeterTemplates(
      settingsStore.ignoreVersion ? undefined : version
    )
      .then(
        (value: MeterTemplate[]): void => void (this.templateItems = value)
      )
      .catch((error: Error): void => this.showError(error.message));
  }

  private updateDeviceProperty(
    uid: string,
    path: string,
    value: unknown
  ): void {
    this.loading = true;
    try {
      value = JSON.parse(value as string);
    } catch (e) {
      //
    }
    const isLora: boolean = uid !== this.uid;
    let prom: Promise<void>;
    if (isLora) {
      prom = updateLoraDeviceProperty(this.uid, uid, path, value);
    } else {
      prom = updateDeviceProperty(this.deviceType, this.uid, path, value);
    }
    this.dialogMeterUid = '';
    prom
      .then((): void => {
        if (isLora) {
          this.loraShadows = _.merge(
            {},
            this.loraShadows,
            _.set({}, `${uid}.state.desired${path ? '.' + path : ''}`, value)
          );
          if (
            !(
              _.get(
                this.loraShadows,
                `${uid}.state.desired.meter_configuration.basic_config_data`,
                '02'
              ) as string
            ).startsWith('02')
          ) {
            _.unset(
              this.loraShadows,
              `${uid}.state.desired.meter_configuration.revolutions_per_kilo_watt_hour`
            );
          }
        } else {
          this.shadow = _.merge(
            {},
            this.shadow,
            _.set({}, `state.desired${path ? '.' + path : ''}`, value)
          );
          if (
            !(
              this.shadow?.state?.desired?.meter_configuration
                ?.basic_config_data || '02'
            ).startsWith('02')
          ) {
            delete this.shadow?.state?.desired?.meter_configuration
              ?.revolutions_per_kilo_watt_hour;
          }
        }
      })
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private resetDialog(): void {
    this.dialogShow = false;
    this.scannerShow = false;
    this.dialogUid = '';
    this.dialogVerification = '';
    this.dialogMeterUid = '';
    this.dialogBasicConfig = '';
    this.dialogDataConfig = null;
    this.dialogRevolutionsPerKWH = '';
  }

  private addLoraDevice(): void {
    if (!this.deviceType || !this.uid) {
      return;
    }
    this.loading = true;
    addLoraDevice(
      this.uid,
      this.dialogUid,
      this.dialogVerification,
      this.dialogBasicConfig,
      this.dialogDataConfig || '',
      this.dialogBasicConfig.startsWith('02') &&
        this.dialogRevolutionsPerKWH !== '' &&
        !isNaN(this.dialogRevolutionsPerKWH)
        ? this.dialogRevolutionsPerKWH
        : undefined
    )
      .then((): void => {
        this.resetDialog();
        this.load();
      })
      .catch((error: Error): void => {
        this.dialogShow = false;
        this.loading = false;
        this.showError(error.message);
      });
  }

  private removeLoraDevice(): void {
    if (!this.removeUid || this.removeUid === this.uid) {
      return;
    }
    const uid: string = this.removeUid;
    this.loading = true;
    this.removeUid = '';
    removeLoraDevice(this.uid, uid)
      .then((): void => this.$delete(this.loraShadows, uid))
      .catch((error: Error): void => void this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private getLeafValues(
    parent: JsonValue | undefined,
    result: Array<string | number | boolean | null | undefined> = []
  ): Array<string | number | boolean | null | undefined> {
    if (Array.isArray(parent)) {
      result.push(
        ...parent.flatMap(
          (
            child: JsonValue | undefined
          ): Array<string | number | boolean | null | undefined> =>
            this.getLeafValues(child)
        )
      );
    } else if (typeof parent === 'object' && !!parent) {
      result.push(
        ...Object.values(parent).flatMap(
          (
            child: JsonValue | undefined
          ): Array<string | number | boolean | null | undefined> =>
            this.getLeafValues(child)
        )
      );
    } else {
      result.push(parent);
    }
    return result;
  }

  private getLatestTimeByUid(uid: string): number {
    return Math.max(
      ...(this.getLeafValues(
        (uid === this.uid ? this.shadow : this.loraShadows[uid])?.metadata
          .reported
      ).filter(
        (value: string | number | boolean | null | undefined): boolean =>
          typeof value === 'number'
      ) as number[])
    );
  }
}
