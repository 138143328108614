var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-autocomplete',{attrs:{"label":_vm.$vuetify.lang.t(
        ("$vuetify.meter.TEMPLATE" + (_vm.tab == null ? '_REQUIRED' : ''))
      ),"placeholder":_vm.$vuetify.lang.t(
        ("$vuetify.meter.TEMPLATE" + (_vm.tab == null ? '_REQUIRED' : ''))
      ),"rules":_vm.tab == null ? [_vm.requiredRule] : [],"required":_vm.tab == null,"items":_vm.templateItems,"item-text":function (item) { return item.name; },"item-value":function (item) { return item; }},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.template),callback:function ($$v) {_vm.template=$$v},expression:"template"}}),_c('v-tabs',{attrs:{"grow":"","centered":"","optional":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.meter.EXPERT')))]),_c('v-tab',[_vm._v(_vm._s(_vm.$vuetify.lang.t('$vuetify.meter.BASIC')))])],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.meter.TYPE_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.TYPE_REQUIRED'),"rules":[_vm.requiredRule],"items":_vm.typeItems,"required":""},on:{"change":_vm.setTypeDefaults},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.basicObj.type),callback:function ($$v) {_vm.$set(_vm.basicObj, "type", $$v)},expression:"basicObj.type"}}),(_vm.basicObj.type !== 'IO-Counter')?[_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.meter.BAUD_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.BAUD_REQUIRED'),"rules":[_vm.requiredRule],"items":_vm.baudItems,"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872),model:{value:(_vm.basicObj.baud),callback:function ($$v) {_vm.$set(_vm.basicObj, "baud", $$v)},expression:"basicObj.baud"}}),_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.meter.PARITY_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.PARITY_REQUIRED'),"rules":[_vm.requiredRule],"items":_vm.parityItems,"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872),model:{value:(_vm.basicObj.parity),callback:function ($$v) {_vm.$set(_vm.basicObj, "parity", $$v)},expression:"basicObj.parity"}}),_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.meter.STOPBITS_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.STOPBITS_REQUIRED'),"rules":[_vm.requiredRule],"items":_vm.stopbitsItems,"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872),model:{value:(_vm.basicObj.stopbits),callback:function ($$v) {_vm.$set(_vm.basicObj, "stopbits", $$v)},expression:"basicObj.stopbits"}}),_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.meter.LENGTH_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.LENGTH_REQUIRED'),"rules":[_vm.requiredRule],"items":_vm.lengthItems,"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872),model:{value:(_vm.basicObj.length),callback:function ($$v) {_vm.$set(_vm.basicObj, "length", $$v)},expression:"basicObj.length"}}),_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.meter.INVERSION_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.INVERSION_REQUIRED'),"rules":[_vm.requiredRule],"items":_vm.inversionItems,"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872),model:{value:(_vm.basicObj.inversion),callback:function ($$v) {_vm.$set(_vm.basicObj, "inversion", $$v)},expression:"basicObj.inversion"}}),_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.meter.MODE_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.MODE_REQUIRED'),"rules":[_vm.requiredRule],"items":_vm.modeItems,"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872),model:{value:(_vm.basicObj.mode),callback:function ($$v) {_vm.$set(_vm.basicObj, "mode", $$v)},expression:"basicObj.mode"}})]:_vm._e(),_c('v-text-field',{attrs:{"value":_vm.basicObj.interval,"label":_vm.$vuetify.lang.t('$vuetify.meter.INTERVAL_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.INTERVAL_REQUIRED'),"rules":[_vm.requiredRule, _vm.intervalRule],"type":"number","min":0,"max":65535,"required":""},on:{"input":function($event){_vm.basicObj.interval = parseInt($event, 10)},"keydown":_vm.validateNumberKeydown,"paste":_vm.validateNumberPaste,"drop":_vm.validateNumberPaste},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}])}),(_vm.basicObj.type !== 'IO-Counter')?[_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.meter.PROTOCOL_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.PROTOCOL_REQUIRED'),"rules":[_vm.requiredRule],"items":_vm.protocolItems,"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872),model:{value:(_vm.basicObj.protocol),callback:function ($$v) {_vm.$set(_vm.basicObj, "protocol", $$v)},expression:"basicObj.protocol"}}),_c('v-select',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.meter.SECURITY_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.SECURITY_REQUIRED'),"rules":[_vm.requiredRule],"items":_vm.securityItems,"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
      var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872),model:{value:(_vm.basicObj.security),callback:function ($$v) {_vm.$set(_vm.basicObj, "security", $$v)},expression:"basicObj.security"}})]:_vm._e(),(
          _vm.basicObj &&
          _vm.basicObj.type !== 'IO-Counter' &&
          _vm.basicObj.security &&
          _vm.basicObj.security !== 'NONE'
        )?[_c('v-text-field',{attrs:{"value":_vm.basicObj.key1 || _vm.key1Fallback,"label":_vm.$vuetify.lang.t('$vuetify.meter.KEY1_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.KEY1_REQUIRED'),"rules":[_vm.requiredRule, _vm.hexRule],"counter":32,"required":""},on:{"input":function($event){_vm.basicObj.key1 = $event;
            if ($event) {
              _vm.key1Fallback = $event;
            }},"keydown":_vm.validateHexKeydown,"paste":_vm.validateHexPaste,"drop":_vm.validateHexPaste},scopedSlots:_vm._u([{key:"message",fn:function(ref){
            var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872)}),_c('v-text-field',{attrs:{"value":_vm.basicObj.key2,"label":_vm.$vuetify.lang.t('$vuetify.meter.KEY2_REQUIRED'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.KEY2_REQUIRED'),"rules":[_vm.requiredRule, _vm.hexRule],"counter":32,"required":""},on:{"input":function($event){_vm.basicObj.key2 = $event;
            if ($event) {
              _vm.key2Fallback = $event;
            }},"keydown":_vm.validateHexKeydown,"paste":_vm.validateHexPaste,"drop":_vm.validateHexPaste},scopedSlots:_vm._u([{key:"message",fn:function(ref){
            var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872)})]:_vm._e(),_c('v-combobox',{attrs:{"chips":"","deletable-chips":"","multiple":"","counter":10,"label":_vm.$vuetify.lang.t('$vuetify.meter.DATA_CONFIG'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.DATA_CONFIG'),"rules":[_vm.requiredRule, _vm.countRule],"items":_vm.dataItems,"item-text":_vm.getText,"search-input":_vm.dataArrSearch,"required":""},on:{"update:searchInput":function($event){_vm.dataArrSearch=$event},"update:search-input":function($event){_vm.dataArrSearch=$event}},scopedSlots:_vm._u([{key:"message",fn:function(ref){
            var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.dataObjArr),callback:function ($$v) {_vm.dataObjArr=$$v},expression:"dataObjArr"}})],2),_c('v-tab-item',[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.meter.BASIC_CONFIG'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.BASIC_CONFIG'),"rules":[_vm.requiredRule],"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
            var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.basicHex),callback:function ($$v) {_vm.basicHex=$$v},expression:"basicHex"}}),_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.meter.DATA_CONFIG'),"placeholder":_vm.$vuetify.lang.t('$vuetify.meter.DATA_CONFIG'),"rules":[_vm.requiredRule, _vm.lengthRule],"counter":100,"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
            var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.dataHex),callback:function ($$v) {_vm.dataHex=$$v},expression:"dataHex"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }