var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"background"},[_c('v-subheader',{staticStyle:{"position":"relative"}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.STATE'))+" "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-switch',{staticClass:"mt-0",staticStyle:{"transform":"scale(0.8)","margin-right":"-8px"},attrs:{"dense":"","hide-details":""},model:{value:(_vm.isLive),callback:function ($$v) {_vm.isLive=$$v},expression:"isLive"}})],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.LIVE'))+" ")])]),_c('v-text-field',{staticClass:"mt-0",style:({
        transition:
          'max-width 0.5s ease-in-out, padding-left 0.5s ease-in-out',
        maxWidth: _vm.searchTable !== false ? '300px' : 0,
        paddingLeft: _vm.searchTable === false ? '28px' : 0
      }),attrs:{"value":_vm.searchTable || '',"append-icon":_vm.mdiMagnify,"dense":"","hide-details":"","single-line":"","autofocus":"","label":_vm.$vuetify.lang.t('$vuetify.device.SEARCH'),"placeholder":_vm.$vuetify.lang.t('$vuetify.device.SEARCH'),"type":"search"},on:{"input":function($event){_vm.$router
          .replace({
            query: Object.assign({}, _vm.$route.query, {search: ($event || '').trim()})
          })
          .catch(_vm.noop)},"blur":function($event){_vm.searchTable ||
          _vm.$router
            .replace({ query: Object.assign({}, _vm.$route.query, {search: undefined}) })
            .catch(_vm.noop)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.$router
          .replace({ query: Object.assign({}, _vm.$route.query, {search: undefined}) })
          .catch(_vm.noop)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('span',{staticClass:"pa-3"}),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"right":"0"},attrs:{"icon":"","small":"","absolute":""},on:{"click":function($event){_vm.$router
                  .replace({ query: Object.assign({}, _vm.$route.query, {search: ''}) })
                  .catch(_vm.noop)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiMagnify)+" ")])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.SEARCH'))+" ")])])]},proxy:true}])}),(_vm.deviceType !== _vm.DeviceType.GATEWAY && _vm.userGroups.length)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":("/production?search=" + _vm.uid)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiFactory))])],1)]}}],null,false,3470797084)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.OPEN_PRODUCTION'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":("/shadows/" + _vm.uid + "?search=" + _vm.uid)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiGhost))])],1)]}}],null,false,1584509900)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.OPEN_SHADOW'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":("/logs/devices?search=" + _vm.uid)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiPaperRoll))])],1)]}}],null,false,2683415891)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.OPEN_LOGS'))+" ")])])]:_vm._e(),(_vm.deviceType !== _vm.DeviceType.GATEWAY)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialogMeterUid = _vm.uid}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiCounter))])],1)]}}],null,false,494360733)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.METER_CONFIG'))+" ")])]):_vm._e(),(_vm.deviceType === _vm.DeviceType.GATEWAY)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.showDialog}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiWifiPlus))])],1)]}}],null,false,763530746)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.ADD_LORA'))+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":_vm.load}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiRefresh))])],1)]}}])},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.REFRESH_ALL'))+" ")])])],2),_c('div',{style:(_vm.$vuetify.breakpoint.mdAndUp
        ? 'height: calc(100vh - 64px - 48px); position: relative;'
        : 'height: 100%; position: relative')},[_c('v-data-table',{style:(_vm.$vuetify.breakpoint.mdAndUp ? '' : 'height: 100%'),attrs:{"loading":_vm.loading,"headers":_vm.deviceType === _vm.DeviceType.GATEWAY
          ? [_vm.deviceTableHeader ].concat( _vm.tableHeaders)
          : _vm.tableHeaders,"items":_vm.tableItems.concat( _vm.loraTableItems),"sort-by":"path","must-sort":"","group-by":_vm.deviceType === _vm.DeviceType.GATEWAY ? 'uid' : undefined,"search":_vm.searchTable || '',"custom-filter":function (value, search, item) { return JSON.stringify(item).toUpperCase().includes(search.toUpperCase()); },"disable-pagination":"","fixed-header":"","height":_vm.$vuetify.breakpoint.mdAndUp ? 'calc(100vh - 64px - 48px)' : '',"hide-default-footer":"","items-per-page":-1,"footer-props":{
        disablePagination: true,
        disableItemsPerPage: true
      }},scopedSlots:_vm._u([{key:"group.header",fn:function(ref){
      var headers = ref.headers;
      var group = ref.group;
      var isOpen = ref.isOpen;
      var toggle = ref.toggle;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-row',{staticStyle:{"min-height":"100%","cursor":"pointer"},attrs:{"dense":"","no-gutters":"","align":"center"},on:{"click":toggle}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-4",attrs:{"icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{class:{ 'rotate-chevron': isOpen }},[_vm._v(" "+_vm._s(_vm.mdiChevronDown)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( ("$vuetify.device." + (isOpen ? 'COLLAPSE' : 'EXPAND')) ))+" ")])]),_c('span',{staticClass:"font-weight-medium",attrs:{"title":_vm._f("moment")(_vm.getLatestTimeByUid(group))}},[_vm._v(" "+_vm._s(group)+" ")]),_c('v-spacer'),(_vm.deviceType === _vm.DeviceType.GATEWAY && _vm.userGroups.length)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":("/production?search=" + group)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiFactory))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.OPEN_PRODUCTION'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":("/shadows/" + group + "?search=" + group)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiGhost))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.OPEN_SHADOW'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":"","to":("/logs/devices?search=" + _vm.uid)}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiPaperRoll))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.OPEN_LOGS'))+" ")])])]:_vm._e(),(group === _vm.uid)?[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.getDevice()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiRefresh))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.REFRESH'))+" ")])])]:[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialogMeterUid = group}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiCounter))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.METER_CONFIG'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.getLoraDevice(group)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(_vm.mdiRefresh))])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.REFRESH'))+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
      var on = ref.on;
      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.removeUid = group}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiClose)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.REMOVE_INTERFACES'))+" ")])])]],2)],1)]}},{key:"item.path",fn:function(ref){
      var item = ref.item;
return [_c('v-row',{staticStyle:{"position":"relative","padding-right":"32px"},attrs:{"dense":"","no-gutters":"","align":"center"}},[_vm._v(" "+_vm._s(_vm.getDescription(item.path))+" "),(
              Array.isArray(_vm.availableHistoryMeasurements[item.uid]) &&
              _vm.availableHistoryMeasurements[item.uid].includes(
                item.path.split('.').pop()
              )
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"position":"absolute","right":"0"},attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.dialogBasicMeasurement = {
                    uid: item.uid,
                    measurement: item.path.split('.').pop()
                  }}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiChartLine)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.SHOW_CHART'))+" ")])]):_vm._e()],1)]}},{key:"item.reported",fn:function(ref){
                  var item = ref.item;
return [_c('v-row',{attrs:{"dense":"","no-gutters":"","align":"center","title":_vm._f("moment")(item.reported.timestamp)}},[(
              item.path === 'meter_configuration.data_config_data' &&
              item.reported.value != null &&
              item.reported.value !== ''
            )?_c('div',[_c('div',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s((item.reported.value.match(/.{1,10}/g) || ['-']).join('\n'))+" ")])]):(
              item.path === 'meter_configuration.basic_config_data' &&
              item.reported.value != null &&
              item.reported.value !== ''
            )?_c('div',[_c('div',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(( item.reported.value.match(/^(.{0,12})(.{0,32})(.*)$/) || [ '-' ] ) .slice(1) .filter(Boolean) .join('\n'))+" ")])]):(
              item.reported.value != null &&
              item.reported.value !== '' &&
              !['not supported', 'not available'].includes(
                item.reported.value
              )
            )?_c('div',[_vm._v(" "+_vm._s(((item.reported.value) + " " + (_vm.getUnit(item.path))))+" ")]):[_vm._v(" "+_vm._s(item.reported.value != null && item.reported.value !== '' ? item.reported.value : '-')+" ")]],2)]}},{key:"item.desired",fn:function(ref){
            var item = ref.item;
return [_c('v-edit-dialog',{attrs:{"large":"","cancel-text":_vm.$vuetify.lang.t('$vuetify.device.CANCEL'),"save-text":_vm.$vuetify.lang.t('$vuetify.device.SAVE')},on:{"save":function($event){return _vm.updateDeviceProperty(item.uid, item.path, item.tmp)},"open":function($event){item.tmp = item.desired.value}},scopedSlots:_vm._u([{key:"input",fn:function(){return [(
                item.path.startsWith('log.logger.') &&
                item.path.endsWith('.level')
              )?_c('v-select',{attrs:{"items":_vm.loggerLevels,"label":item.path,"single-line":""},model:{value:(item.tmp),callback:function ($$v) {_vm.$set(item, "tmp", $$v)},expression:"item.tmp"}}):_c('v-text-field',{attrs:{"label":item.path,"placeholder":item.path,"single-line":""},model:{value:(item.tmp),callback:function ($$v) {_vm.$set(item, "tmp", $$v)},expression:"item.tmp"}})]},proxy:true}],null,true)},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-row',_vm._g(_vm._b({staticClass:"flex-nowrap",staticStyle:{"min-height":"100%"},attrs:{"dense":"","no-gutters":"","align":"center","title":_vm._f("moment")(item.desired.timestamp)}},'v-row',attrs,false),on),[(
                    item.path === 'meter_configuration.data_config_data' &&
                    item.desired.value != null &&
                    item.desired.value !== ''
                  )?_c('div',[_c('div',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s((item.desired.value.match(/.{1,10}/g) || ['-']).join( '\n' ))+" ")])]):(
                    item.path === 'meter_configuration.basic_config_data' &&
                    item.desired.value != null &&
                    item.desired.value !== ''
                  )?_c('div',[_c('div',{staticStyle:{"white-space":"pre-line"}},[_vm._v(" "+_vm._s(( item.desired.value.match( /^(.{0,12})(.{0,32})(.*)$/ ) || ['-'] ) .slice(1) .filter(Boolean) .join('\n'))+" ")])]):[_vm._v(" "+_vm._s(item.desired.value != null && item.desired.value !== '' ? item.desired.value : '-')+" ")],_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.mdiPencil)+" ")])],1)],2)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.production.EDIT'))+" ")])])],1)]}}])})],1),_c('v-dialog',{attrs:{"max-width":"500"},on:{"click:outside":_vm.resetDialog},model:{value:(_vm.dialogShow),callback:function ($$v) {_vm.dialogShow=$$v},expression:"dialogShow"}},[_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.dialogValid),callback:function ($$v) {_vm.dialogValid=$$v},expression:"dialogValid"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.ADD_LORA'))+" ")]),_c('v-spacer'),(_vm.hasWebcam)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
                  var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.scannerShow = !_vm.scannerShow}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.scannerShow ? _vm.mdiFormTextbox : _vm.mdiQrcodeScan))])],1)]}}],null,false,1642966110)},[_c('span',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t( _vm.scannerShow ? '$vuetify.device.HIDE_SCANNER' : '$vuetify.device.SHOW_SCANNER' ))+" ")])]):_vm._e()],1),_c('v-card-text',[(_vm.scannerShow)?[_c('QrcodeStream',{attrs:{"track":_vm.scannerError ? _vm.paintError : _vm.paintOutline},on:{"init":_vm.scannerInit,"decode":_vm.decodeQR}}),(_vm.scannerLoading)?_c('v-progress-circular',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%)"},attrs:{"indeterminate":"","color":"primary"}}):_vm._e()]:[_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.device.UID'),"placeholder":_vm.$vuetify.lang.t('$vuetify.device.UID'),"rules":[_vm.requiredRule],"required":"","autofocus":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                  var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.dialogUid),callback:function ($$v) {_vm.dialogUid=$$v},expression:"dialogUid"}}),_c('v-text-field',{attrs:{"label":_vm.$vuetify.lang.t('$vuetify.device.VERIFICATION'),"placeholder":_vm.$vuetify.lang.t('$vuetify.device.VERIFICATION'),"rules":[_vm.requiredRule],"required":""},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                  var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}]),model:{value:(_vm.dialogVerification),callback:function ($$v) {_vm.dialogVerification=$$v},expression:"dialogVerification"}}),_c('MeterConfigComponent',{attrs:{"templateItems":_vm.templateItems,"dataItems":_vm.dataItems,"basic":_vm.dialogBasicConfig,"data":_vm.dialogDataConfig},on:{"update:basic":function($event){_vm.dialogBasicConfig=$event},"update:data":function($event){_vm.dialogDataConfig=$event}}}),(_vm.dialogMeterBasic.startsWith('02'))?_c('v-text-field',{attrs:{"value":_vm.dialogRPKWH,"label":_vm.$vuetify.lang.t('$vuetify.device.REVOLUTIONS_FACTOR'),"placeholder":_vm.$vuetify.lang.t('$vuetify.device.REVOLUTIONS_FACTOR'),"rules":[_vm.intervalRule],"type":"number","min":37.5,"max":600,"step":0.5,"required":""},on:{"input":function($event){$event = $event.replace(',', '.');
                _vm.dialogRevolutionsPerKWH = parseFloat($event);},"keydown":_vm.validateNumberKeydown,"paste":_vm.validateNumberPaste,"drop":_vm.validateNumberPaste},scopedSlots:_vm._u([{key:"message",fn:function(ref){
                var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872)}):_vm._e()]],2),_c('v-card-actions',[_c('small',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.REQUIRED_FIELD'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.resetDialog}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.scannerShow || _vm.loading || !_vm.dialogValid},on:{"click":_vm.addLoraDevice}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.ADD'))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"value":!!_vm.dialogMeterUid,"max-width":"500"},on:{"input":function($event){_vm.dialogMeterUid = ''},"click:outside":_vm.resetDialog}},[_c('v-card',[_c('v-form',{ref:"form",model:{value:(_vm.dialogMeterValid),callback:function ($$v) {_vm.dialogMeterValid=$$v},expression:"dialogMeterValid"}},[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.METER_CONFIG'))+" ")])]),_c('v-card-text',[(!!_vm.dialogMeterUid)?_c('MeterConfigComponent',{attrs:{"defaultTab":0,"templateItems":_vm.templateItems,"dataItems":_vm.dataItems,"basic":_vm.dialogMeterBasic,"data":_vm.dialogMeterData},on:{"update:basic":function($event){_vm.dialogBasicConfig = $event},"update:data":function($event){_vm.dialogDataConfig = $event}}}):_vm._e(),(_vm.dialogMeterBasic.startsWith('02'))?_c('v-text-field',{attrs:{"value":_vm.dialogRPKWH,"label":_vm.$vuetify.lang.t('$vuetify.device.REVOLUTIONS_FACTOR'),"placeholder":_vm.$vuetify.lang.t('$vuetify.device.REVOLUTIONS_FACTOR'),"rules":[_vm.intervalRule],"type":"number","min":37.5,"max":600,"step":0.5,"required":""},on:{"input":function($event){$event = $event.replace(',', '.');
              _vm.dialogRevolutionsPerKWH = parseFloat($event);},"keydown":_vm.validateNumberKeydown,"paste":_vm.validateNumberPaste,"drop":_vm.validateNumberPaste},scopedSlots:_vm._u([{key:"message",fn:function(ref){
              var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$vuetify.lang.t(message))+" ")]}}],null,false,2033371872)}):_vm._e()],1),_c('v-card-actions',[_c('small',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.REQUIRED_FIELD'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":_vm.resetDialog}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success","loading":_vm.loading,"disabled":_vm.loading || !_vm.dialogMeterValid},on:{"click":function($event){_vm.updateDeviceProperty(_vm.dialogMeterUid, '', {
                meter_configuration: Object.assign({}, {basic_config_data: _vm.dialogBasicConfig,
                  data_config_data: _vm.dialogDataConfig},
                  (_vm.dialogBasicConfig.startsWith('02') &&
                  _vm.dialogRevolutionsPerKWH !== '' &&
                  !isNaN(_vm.dialogRevolutionsPerKWH)
                    ? {
                        revolutions_per_kilo_watt_hour:
                          _vm.dialogRevolutionsPerKWH
                      }
                    : {}))
              })}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.SAVE'))+" ")])],1)],1)],1)],1),_c('v-dialog',{attrs:{"value":!!_vm.removeUid,"max-width":"400"},on:{"input":function($event){_vm.removeUid = ''}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.REMOVE_INTERFACES'))+" ")]),_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.removeUid)+" ")]),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.CONFIRM_REMOVE_INTERFACES'))+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.removeUid = ''}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.CANCEL'))+" ")]),_c('v-btn',{attrs:{"color":"success"},on:{"click":_vm.removeLoraDevice}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.REMOVE'))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"value":!!_vm.dialogBasicMeasurement,"max-width":"1000"},on:{"input":function($event){_vm.dialogBasicMeasurement = null}}},[(_vm.dialogBasicMeasurement)?_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.measurements[_vm.dialogBasicMeasurement.measurement] ? _vm.measurements[_vm.dialogBasicMeasurement.measurement].description[ _vm.$vuetify.lang.current ] || _vm.measurements[_vm.dialogBasicMeasurement.measurement].description[ 'en' ] || _vm.dialogBasicMeasurement.measurement : _vm.dialogBasicMeasurement.measurement)+" ")]),(_vm.measurements[_vm.dialogBasicMeasurement.measurement])?_c('v-card-subtitle',[_vm._v(" "+_vm._s(_vm.dialogBasicMeasurement.measurement)+" ")]):_vm._e(),_c('v-card-text',[_c('MeasurementChartComponent',{attrs:{"uid":_vm.dialogBasicMeasurement.uid,"measurement":_vm.dialogBasicMeasurement.measurement,"unit":_vm.measurements[_vm.dialogBasicMeasurement.measurement]
              ? _vm.measurements[_vm.dialogBasicMeasurement.measurement].unit || ''
              : ''}})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){_vm.dialogBasicMeasurement = null}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.CLOSE'))+" ")])],1)],1):_vm._e()],1),_c('v-dialog',{attrs:{"value":!!_vm.update,"max-width":"400"},on:{"input":function($event){return _vm.hideUpdate()}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 mb-2"},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.UPDATE_PROGRESS'))+" ")]),(_vm.update)?[_c('v-card-subtitle',[_vm._v("v"+_vm._s(_vm.update.version))]),_c('v-card-text',{staticStyle:{"text-align":"center"}},[_c('v-progress-circular',{attrs:{"size":150,"width":15,"rotate":-90,"value":_vm.downloadProgress,"color":"primary"}},[_vm._v(" "+_vm._s(_vm.update.downloadProgress || _vm.$vuetify.lang.t('$vuetify.device.SELF_TEST'))+" ")])],1)]:_vm._e(),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{on:{"click":function($event){return _vm.hideUpdate()}}},[_vm._v(" "+_vm._s(_vm.$vuetify.lang.t('$vuetify.device.CLOSE'))+" ")])],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }