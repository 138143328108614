export const typeItems: readonly ['UART', 'IO-Counter', 'ADC', 'MBus'] = [
  'UART',
  'IO-Counter',
  'ADC',
  'MBus'
] as const;
export type Type = typeof typeItems[number];

export const baudItems: readonly [
  300,
  600,
  1200,
  2400,
  4800,
  9600,
  14400,
  19200,
  38400,
  57600,
  115200,
  230400,
  460800,
  921600
] = [
  300, 600, 1200, 2400, 4800, 9600, 14400, 19200, 38400, 57600, 115200, 230400,
  460800, 921600
] as const;
export type Baud = typeof baudItems[number];

export const parityItems: readonly ['NONE', 'EVEN', 'ODD'] = [
  'NONE',
  'EVEN',
  'ODD'
] as const;
export type Parity = typeof parityItems[number];

export const stopbitsItems: readonly [1, 1.5, 2] = [1, 1.5, 2] as const;
export type Stopbits = typeof stopbitsItems[number];

export const lengthItems: readonly [7, 8] = [7, 8] as const;
export type Length = typeof lengthItems[number];

export const inversionItems: readonly ['NOT', 'RX', 'TX'] = [
  'NOT',
  'RX',
  'TX'
] as const;
export type Inversion = typeof inversionItems[number];

export const modeItems: readonly ['POLLING', 'AUTO'] = [
  'POLLING',
  'AUTO'
] as const;
export type Mode = typeof modeItems[number];

export const protocolItems: readonly [
  'DSMR',
  'DLMS',
  'MBus',
  'w-MBus',
  'SML',
  'IEC 62056-21 (Mode D)',
  'IEC 62056-21 (Mode C)',
  'MEP'
] = [
  'DSMR',
  'DLMS',
  'MBus',
  'w-MBus',
  'SML',
  'IEC 62056-21 (Mode D)',
  'IEC 62056-21 (Mode C)',
  'MEP'
] as const;
export type Protocol = typeof protocolItems[number];

export const securityItems: readonly ['NONE', 'AES128-GCM'] = [
  'NONE',
  'AES128-GCM'
] as const;
export type Security = typeof securityItems[number];

export interface IBasicConfig {
  type?: Type;
  baud?: Baud;
  parity?: Parity;
  stopbits?: Stopbits;
  length?: Length;
  inversion?: Inversion;
  mode?: Mode;
  interval?: number;
  protocol?: Protocol;
  security?: Security;
  key1?: string;
  key2?: string;
}

export class BasicConfig implements IBasicConfig {
  public type?: Type;
  public baud?: Baud;
  public parity?: Parity;
  public stopbits?: Stopbits;
  public length?: Length;
  public inversion?: Inversion;
  public mode?: Mode;
  public interval?: number;
  public protocol?: Protocol;
  public security?: Security;
  public key1?: string;
  public key2?: string;

  public constructor(init?: IBasicConfig) {
    if (!init) {
      return;
    } else if (typeof init === 'object') {
      Object.assign(this, init);
    } else {
      throw new Error('Out of range');
    }
  }

  public static fromString(hex?: string): BasicConfig {
    if (!hex || !/^[0-9A-F]{12}(?:[0-9A-F]{64})?$/i.test(hex)) {
      throw new Error('Expected Hex String');
    }
    const binary: string = parseInt(hex.substr(0, 12), 16)
      .toString(2)
      .padStart(12 * 4, '0');
    const security: Security = BasicConfig.byteToSecurity(binary.substr(44, 4));
    return new BasicConfig({
      type: BasicConfig.byteToType(binary.substr(0, 8)),
      baud: BasicConfig.byteToBaud(binary.substr(8, 4)),
      parity: BasicConfig.byteToParity(binary.substr(12, 2)),
      stopbits: BasicConfig.byteToStopbits(binary.substr(14, 2)),
      length: BasicConfig.byteToLength(binary.substr(16, 2)),
      inversion: BasicConfig.byteToInversion(binary.substr(18, 2)),
      mode: BasicConfig.byteToMode(binary.substr(20, 4)),
      interval: BasicConfig.byteToInterval(binary.substr(24, 16)),
      protocol: BasicConfig.byteToProtocol(binary.substr(40, 4)),
      security,
      ...(security === 'AES128-GCM'
        ? { key1: hex.substr(12, 32), key2: hex.substr(44, 32) }
        : {})
    });
  }

  private static byteToType(value: string): Type {
    switch (parseInt(value, 2)) {
      case 1:
        return 'UART';
      case 2:
        return 'IO-Counter';
      case 3:
        return 'ADC';
      case 4:
        return 'MBus';
      default:
        throw new Error('Out of range');
    }
  }

  private static byteToBaud(value: string): Baud {
    switch (parseInt(value, 2)) {
      case 0:
        return 300;
      case 1:
        return 600;
      case 2:
        return 1200;
      case 3:
        return 2400;
      case 4:
        return 4800;
      case 5:
        return 9600;
      case 6:
        return 14400;
      case 7:
        return 19200;
      case 8:
        return 38400;
      case 9:
        return 57600;
      case 10:
        return 115200;
      case 11:
        return 230400;
      case 12:
        return 460800;
      case 13:
        return 921600;
      default:
        throw new Error('Out of range');
    }
  }

  private static byteToParity(value: string): Parity {
    switch (parseInt(value, 2)) {
      case 0:
        return 'NONE';
      case 1:
        return 'EVEN';
      case 2:
        return 'ODD';
      default:
        throw new Error('Out of range');
    }
  }

  private static byteToStopbits(value: string): Stopbits {
    switch (parseInt(value, 2)) {
      case 0:
        return 1;
      case 1:
        return 1.5;
      case 2:
        return 2;
      default:
        throw new Error('Out of range');
    }
  }

  private static byteToLength(value: string): Length {
    switch (parseInt(value, 2)) {
      case 0:
        return 7;
      case 1:
        return 8;
      default:
        throw new Error('Out of range');
    }
  }

  private static byteToInversion(value: string): Inversion {
    switch (parseInt(value, 2)) {
      case 0:
        return 'NOT';
      case 1:
        return 'RX';
      case 2:
        return 'TX';
      default:
        throw new Error('Out of range');
    }
  }

  private static byteToMode(value: string): Mode {
    switch (parseInt(value, 2)) {
      case 0:
        return 'POLLING';
      case 1:
        return 'AUTO';
      default:
        throw new Error('Out of range');
    }
  }

  private static byteToInterval(value: string): number {
    return parseInt(value, 2);
  }

  private static byteToProtocol(value: string): Protocol {
    switch (parseInt(value, 2)) {
      case 0:
        return 'DSMR';
      case 1:
        return 'DLMS';
      case 2:
        return 'MBus';
      case 3:
        return 'w-MBus';
      case 4:
        return 'SML';
      case 5:
        return 'IEC 62056-21 (Mode D)';
      case 6:
        return 'IEC 62056-21 (Mode C)';
      case 7:
        return 'MEP';
      default:
        throw new Error('Out of range');
    }
  }

  private static byteToSecurity(value: string): Security {
    switch (parseInt(value, 2)) {
      case 0:
        return 'NONE';
      case 1:
        return 'AES128-GCM';
      default:
        throw new Error('Out of range');
    }
  }

  public toString(): string {
    const binary: string =
      BasicConfig.typeToByte(this.type) +
      BasicConfig.baudToByte(this.baud) +
      BasicConfig.parityToByte(this.parity) +
      BasicConfig.stopbitsToByte(this.stopbits) +
      BasicConfig.lengthToByte(this.length) +
      BasicConfig.inversionToByte(this.inversion) +
      BasicConfig.modeToByte(this.mode) +
      BasicConfig.intervalToByte(this.interval) +
      BasicConfig.protocolToByte(this.protocol) +
      BasicConfig.securityToByte(this.security);
    return (
      parseInt(binary, 2).toString(16).padStart(12, '0') +
      (this.security === 'AES128-GCM'
        ? (this.key1 || '') + (this.key2 || '')
        : '')
    ).toUpperCase();
  }

  private static typeToByte(value?: Type): string {
    switch (value) {
      case 'UART':
        return '00000001';
      case 'IO-Counter':
        return '00000010';
      case 'ADC':
        return '00000011';
      case 'MBus':
        return '00000100';
      default:
        throw new Error('Out of range');
    }
  }

  private static baudToByte(value?: Baud): string {
    switch (value) {
      case 300:
        return '0000';
      case 600:
        return '0001';
      case 1200:
        return '0010';
      case 2400:
        return '0011';
      case 4800:
        return '0100';
      case 9600:
        return '0101';
      case 14400:
        return '0110';
      case 19200:
        return '0111';
      case 38400:
        return '1000';
      case 57600:
        return '1001';
      case 115200:
        return '1010';
      case 230400:
        return '1011';
      case 460800:
        return '1100';
      case 921600:
        return '1101';
      default:
        throw new Error('Out of range');
    }
  }

  private static parityToByte(value?: Parity): string {
    switch (value) {
      case 'NONE':
        return '00';
      case 'EVEN':
        return '01';
      case 'ODD':
        return '10';
      default:
        throw new Error('Out of range');
    }
  }

  private static stopbitsToByte(value?: Stopbits): string {
    switch (value) {
      case 1:
        return '00';
      case 1.5:
        return '01';
      case 2:
        return '10';
      default:
        throw new Error('Out of range');
    }
  }

  private static lengthToByte(value?: Length): string {
    switch (value) {
      case 7:
        return '00';
      case 8:
        return '01';
      default:
        throw new Error('Out of range');
    }
  }

  private static inversionToByte(value?: Inversion): string {
    switch (value) {
      case 'NOT':
        return '00';
      case 'RX':
        return '01';
      case 'TX':
        return '10';
      default:
        throw new Error('Out of range');
    }
  }

  private static modeToByte(value?: Mode): string {
    switch (value) {
      case 'POLLING':
        return '0000';
      case 'AUTO':
        return '0001';
      default:
        throw new Error('Out of range');
    }
  }

  private static intervalToByte(value?: number): string {
    if (value == undefined) {
      throw new Error('Out of range');
    }
    const binary: string = value.toString(2).padStart(16, '0');
    if (binary.length > 16) {
      throw new Error('Out of range');
    }
    return binary;
  }

  private static protocolToByte(value?: Protocol): string {
    switch (value) {
      case 'DSMR':
        return '0000';
      case 'DLMS':
        return '0001';
      case 'MBus':
        return '0010';
      case 'w-MBus':
        return '0011';
      case 'SML':
        return '0100';
      case 'IEC 62056-21 (Mode D)':
        return '0101';
      case 'IEC 62056-21 (Mode C)':
        return '0110';
      case 'MEP':
        return '0111';
      default:
        throw new Error('Out of range');
    }
  }

  private static securityToByte(value?: Security): string {
    switch (value) {
      case 'NONE':
        return '0000';
      case 'AES128-GCM':
        return '0001';
      default:
        throw new Error('Out of range');
    }
  }
}
