




































































































































































































































































import {
  Vue,
  Component,
  Watch,
  PropSync,
  Prop
} from 'vue-property-decorator';
import {
  BasicConfig,
  Baud,
  baudItems,
  Inversion,
  inversionItems,
  Length,
  lengthItems,
  Mode,
  modeItems,
  Parity,
  parityItems,
  Protocol,
  protocolItems,
  Security,
  securityItems,
  Stopbits,
  stopbitsItems,
  Type,
  typeItems
} from '../typings/basicConfig';
import type { MeterTemplate, ValidationRule } from '../typings';
import _ from 'lodash';

@Component
export default class MeterConfigComponent extends Vue {
  private readonly requiredRule: ValidationRule = (
    v: unknown
  ): true | string => !!v || '$vuetify.meter.REQUIRED';
  private readonly countRule: ValidationRule = (
    v: unknown[]
  ): true | string =>
    !Array.isArray(v) || v.length <= 10 || '$vuetify.meter.TOO_MANY';
  private readonly lengthRule: ValidationRule = (v: string): true | string =>
    typeof v !== 'string' || v.length <= 100 || '$vuetify.meter.TOO_LONG';
  private readonly intervalRule: ValidationRule = (
    v: unknown
  ): true | string => {
    return (
      !v ||
      (!isNaN(v as number) && parseInt(v as string, 10) <= 65535) ||
      '$vuetify.meter.INVALID'
    );
  };
  private readonly hexRule: ValidationRule = (v: string): true | string =>
    !v ||
    (/^[A-F0-9]+$/i.test(v) && v.length === 32) ||
    '$vuetify.meter.INVALID';
  private readonly typeItems: readonly Type[] = typeItems;
  private readonly baudItems: readonly Baud[] = baudItems;
  private readonly parityItems: readonly Parity[] = parityItems;
  private readonly stopbitsItems: readonly Stopbits[] = stopbitsItems;
  private readonly lengthItems: readonly Length[] = lengthItems;
  private readonly inversionItems: readonly Inversion[] = inversionItems;
  private readonly modeItems: readonly Mode[] = modeItems;
  private readonly protocolItems: readonly Protocol[] = protocolItems;
  private readonly securityItems: readonly Security[] = securityItems;

  private template: MeterTemplate | null = null;
  private basicObj: BasicConfig = new BasicConfig();
  private dataArr: string[] = [];
  private dataArrSearch: string | null = '';
  private tab: 0 | 1 | null = null;
  private key1Fallback: string = '';
  private key2Fallback: string = '';

  private get dataObjArr(): Array<{ value: string } | string> {
    return this.dataArr.map((value: string): { value: string } => ({
      value
    }));
  }

  private set dataObjArr(variabeArr: Array<{ value: string } | string>) {
    if (typeof variabeArr[variabeArr.length - 1] === 'string') {
      const search: string = variabeArr.pop() as string;
      let value: string = search;
      if (
        (/^[A-F0-9]{10}$/i.test(value) ||
          (value = this.obisDecToHex(value)) !== '') &&
        !this.dataArr.includes(value)
      ) {
        this.dataArr.push(value);
        return;
      } else {
        this.$nextTick((): void => void (this.dataArrSearch = search));
      }
    }
    this.dataArr = (variabeArr as Array<{ value: string }>).map(
      ({ value }: { value: string }): string => value
    );
  }

  @PropSync('basic', {
    type: String,
    required: true
  })
  public basicHex!: string;

  @PropSync('data', {
    type: String,
    required: true
  })
  public dataHex!: string;

  @Prop({
    type: Number,
    default: null,
    validator: (value: 0 | 1 | null): boolean => [0, 1, null].includes(value)
  })
  public readonly defaultTab!: 0 | 1 | null;

  @Prop({
    type: Array,
    default: []
  })
  public readonly templateItems!: MeterTemplate[];

  @Prop({
    type: Array,
    default: []
  })
  public readonly dataItems!: Array<{ value: string; text: string }>;

  @Watch('template')
  private onTemplateChanged(template: MeterTemplate | null): void {
    if (
      !template ||
      (_.isEqual(
        _.omit(template.basic, ['__proto__', 'key1', 'key2']),
        _.omit(this.basicObj, ['__proto__', 'key1', 'key2'])
      ) &&
        _.isEqual(_.sortBy(template.data), _.sortBy(this.dataArr)))
    ) {
      return;
    }
    this.basicObj = new BasicConfig(template?.basic);
    this.dataArr = template?.data || [];
    if (this.basicObj.security === 'AES128-GCM') {
      this.basicObj.key1 = this.key1Fallback;
      this.basicObj.key2 = this.key2Fallback;
    }
  }

  @Watch('basicHex')
  private onBasicConfigHexChanged(
    newConfig: string,
    oldConfig: string
  ): void {
    if (newConfig === oldConfig || ![0, 12, 76].includes(newConfig.length)) {
      return;
    }
    try {
      this.basicObj = BasicConfig.fromString(newConfig);
      if (this.basicObj.key1) {
        this.key1Fallback = this.basicObj.key1;
      }
      if (this.basicObj.key2) {
        this.key2Fallback = this.basicObj.key2;
      }
    } catch (e) {
      if (this.tab !== 0) {
        this.basicObj = new BasicConfig();
      }
    }
  }

  @Watch('basicObj.security')
  private onSecurityChanged(security?: Security): void {
    if (security === 'AES128-GCM') {
      this.basicObj.key1 = this.basicObj.key1 || this.key1Fallback;
      this.basicObj.key2 = this.basicObj.key2 || this.key2Fallback;
    }
  }

  @Watch('basicObj', { deep: true })
  private onBasicConfigChanged(config?: BasicConfig): void {
    if (!config) {
      return;
    }
    this.template =
      this.templateItems.find(
        (template: MeterTemplate): boolean =>
          _.isEqual(
            _.omit(template.basic, ['__proto__', 'key1', 'key2']),
            _.omit(config, ['__proto__', 'key1', 'key2'])
          ) && _.isEqual(_.sortBy(template.data), _.sortBy(this.dataArr))
      ) || null;
    try {
      this.basicHex = config.toString();
    } catch (e) {
      if (this.tab !== 1) {
        this.basicHex = '';
      }
    }
  }

  @Watch('dataHex')
  private onDataConfigHexChanged(newConfig: string, oldConfig: string): void {
    if (newConfig === oldConfig || newConfig.length % 10 !== 0) {
      return;
    }
    this.dataArr = newConfig.match(/.{1,10}/g) || [];
  }

  @Watch('dataArr', { deep: true })
  private onDataConfigChanged(config?: string[]): void {
    this.template =
      this.templateItems.find(
        (template: MeterTemplate): boolean =>
          _.isEqual(
            _.omit(template.basic, ['__proto__', 'key1', 'key2']),
            _.omit(this.basicObj, ['__proto__', 'key1', 'key2'])
          ) && _.isEqual(_.sortBy(template.data), _.sortBy(config))
      ) || null;
    this.dataHex = (config || []).join('');
  }

  @Watch('tab')
  private onTabChanged(tab: 0 | 1 | null): void {
    if (tab == null) {
      this.onTemplateChanged(this.template);
    }
  }

  private mounted(): void {
    this.tab = this.defaultTab ?? null;
    this.onBasicConfigHexChanged(this.basicHex, '');
    this.onDataConfigHexChanged(this.dataHex, '');
  }

  private obisDecToHex(code?: string): string {
    if (!/^(\d{1,3})-(\d{1,3}):.*$/.test(code || '')) {
      code = '1-0:' + (code || '');
    }
    const [all, ...groups]: string[] =
      (code || '').match(
        /(\d{1,3})-(\d{1,3}):(\d{1,3})\.(\d{1,3})\.(\d{1,3})/
      ) || [];
    const [a, b, c, d, e]: number[] = groups.map((group: string): number =>
      parseInt(group, 10)
    );
    if (!all || a > 255 || b > 255 || c > 255 || d > 255 || e > 255) {
      return '';
    }
    return (
      a.toString(16).padStart(2, '0') +
      b.toString(16).padStart(2, '0') +
      c.toString(16).padStart(2, '0') +
      d.toString(16).padStart(2, '0') +
      e.toString(16).padStart(2, '0')
    );
  }

  private obisHexToDec(hex: string): string {
    return `${parseInt(hex.substr(0, 2), 16)}-${parseInt(
      hex.substr(2, 2),
      16
    )}:${parseInt(hex.substr(4, 2), 16)}.${parseInt(
      hex.substr(6, 2),
      16
    )}.${parseInt(hex.substr(8, 2), 16)}`;
  }

  private getText(item?: { value: string; text?: string }): string {
    if (!item || !item.value) {
      return '';
    }
    if (item.text) {
      return this.$vuetify.lang.t(item.text);
    }
    return this.obisHexToDec(item.value);
  }

  private setTypeDefaults(type?: Type): void {
    if (type === 'IO-Counter') {
      this.basicObj = new BasicConfig({
        type,
        baud: 300,
        parity: 'NONE',
        stopbits: 1,
        length: 7,
        inversion: 'NOT',
        mode: 'POLLING',
        interval: this.basicObj.interval,
        protocol: 'DSMR',
        security: 'NONE'
      });
    }
  }

  private validateNumberKeydown(event: KeyboardEvent): void {
    const isForbiddenChar: boolean =
      event.key.length === 1 && !/\d/.test(event.key);
    const isAllowedModifier: boolean = event.ctrlKey || event.metaKey;
    if (isForbiddenChar && !isAllowedModifier) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private validateNumberPaste(event: ClipboardEvent | DragEvent): void {
    const data: string =
      (event as ClipboardEvent).clipboardData?.getData('text/plain') ||
      (event as DragEvent).dataTransfer?.getData('text/plain') ||
      '';
    if (!/^\d+$/.test(data)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private validateHexKeydown(event: KeyboardEvent): void {
    const isForbiddenChar: boolean =
      event.key.length === 1 && !/[A-F0-9]/i.test(event.key);
    const isAllowedModifier: boolean = event.ctrlKey || event.metaKey;
    if (isForbiddenChar && !isAllowedModifier) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }

  private validateHexPaste(event: ClipboardEvent | DragEvent): void {
    const data: string =
      (event as ClipboardEvent).clipboardData?.getData('text/plain') ||
      (event as DragEvent).dataTransfer?.getData('text/plain') ||
      '';
    if (!/^[A-F0-9]+$/i.test(data)) {
      event.preventDefault();
      event.stopImmediatePropagation();
    }
  }
}
