





























































































import { deleteHistoryData, getHistoryData } from '../api/user';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Hub } from '@aws-amplify/core';
import VueApexChart from 'vue-apexcharts';
import type ApexCharts from 'apexcharts';
import type { ApexOptions } from 'apexcharts';
import {
  mdiDatabaseRemove,
  mdiDownloadMultiple,
  mdiMagnifyMinus,
  mdiRefresh
} from '@mdi/js';

export const Durations: Record<
  string,
  { start: string; aggregate?: string }
> = {
  TenMinutes: { start: '-10m' },
  OneHour: { start: '-1h', aggregate: '1m' },
  SixHours: { start: '-6h', aggregate: '6m' },
  TwelfHours: { start: '-12h', aggregate: '12m' },
  OneDay: { start: '-1d', aggregate: '24m' },
  OneWeek: { start: '-1w', aggregate: '3h' },
  OneMonth: { start: '-1mo', aggregate: '12h' },
  SixMonths: { start: '-6mo', aggregate: '3d' },
  OneYear: { start: '-1y', aggregate: '6d' }
} as const;

@Component({
  components: {
    VueApexChart
  }
})
export default class MeasurementChartComponent extends Vue {
  private readonly rangeItems: Array<{ text: string; value: string }> =
    Object.keys(Durations).map(
      (key: string): { text: string; value: string } => ({
        text: `$vuetify.chart.DURATION_${key.toUpperCase()}`,
        value: key
      })
    );
  private data: Record<number, unknown> = {};
  private zoomedData: Record<number, unknown> | null = null;
  private loading: boolean = true;
  private showDeleteHistory: boolean = false;
  private start: number = 0;
  private stop: number = 0;
  private range: keyof typeof Durations = 'OneDay';
  private wasClicked: boolean = false;
  private chartOptions: ApexOptions = {
    chart: {
      type: 'line',
      animations: {
        enabled: false
      },
      background: 'transparent',
      defaultLocale: this.$vuetify.lang.current,
      locales: [
        require('apexcharts/dist/locales/de.json'),
        require('apexcharts/dist/locales/en.json')
      ],
      zoom: {
        type: 'x',
        enabled: true,
        autoScaleYaxis: true
      },
      toolbar: {
        autoSelected: 'zoom',
        tools: {
          selection: false,
          zoomin: false,
          zoomout: false,
          pan: false,
          download: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-9 -9 42 42"><path fill="${
            this.$vuetify.theme.dark ? '#FFF' : 'rgba(0, 0, 0, 0.54)'
          }" d="${mdiDownloadMultiple}"></path></svg>`,
          reset: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-9 -9 42 42"><path fill="${
            this.$vuetify.theme.dark ? '#FFF' : 'rgba(0, 0, 0, 0.54)'
          }" d="${mdiMagnifyMinus}"></path></svg>`,
          customIcons: [
            {
              icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-9 -9 42 42"><path fill="${
                this.$vuetify.theme.dark ? '#FFF' : 'rgba(0, 0, 0, 0.54)'
              }" d="${mdiDatabaseRemove}"></path></svg>`,
              title: this.$vuetify.lang.t('$vuetify.chart.DELETE_HISTORY'),
              index: 2,
              click: this.onDeleteHistory
            },
            {
              icon: `<svg xmlns="http://www.w3.org/2000/svg" viewBox="-9 -9 42 42"><path fill="${
                this.$vuetify.theme.dark ? '#FFF' : 'rgba(0, 0, 0, 0.54)'
              }" d="${mdiRefresh}"></path></svg>`,
              title: this.$vuetify.lang.t('$vuetify.chart.REFRESH'),
              index: 0,
              click: (): void => {
                this.loading = true;
                this.getHistoryData();
              }
            }
          ]
        }
      },
      events: {
        beforeZoom: this.onZoom,
        beforeResetZoom: this.onResetZoom,
        click: this.onChartClicked
      }
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      type: 'datetime',
      tooltip: {
        enabled: false
      },
      labels: {
        datetimeFormatter: {
          day: 'dd. MMM'
        }
      }
    },
    yaxis: {
      decimalsInFloat: 0,
      labels: {
        formatter: this.formatYaxis
      }
    },
    markers: {
      size: 0
    },
    colors: [
      this.$vuetify.theme.currentTheme.primary as string,
      this.$vuetify.theme.currentTheme.secondary as string,
      this.$vuetify.theme.currentTheme.accent as string,
      this.$vuetify.theme.currentTheme.info as string,
      this.$vuetify.theme.currentTheme.warning as string,
      this.$vuetify.theme.currentTheme.error as string,
      this.$vuetify.theme.currentTheme.success as string
    ],
    theme: {
      mode: this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    tooltip: {
      x: {
        formatter: (timestamp: number): string =>
          new Date(timestamp).toISOString()
      },
      y: {
        formatter: (value: unknown): string =>
          this.unit ? `${value} ${this.unit}` : `${value}`
      }
    }
  };

  private get heigth(): number {
    return Math.min(400, window.innerHeight * 0.9 - 179);
  }

  private get series(): [number, unknown][] {
    return Object.entries(this.zoomedData || this.data || {}).map(
      ([key, value]: [string, unknown]): [number, unknown] => [
        parseInt(key),
        value
      ]
    );
  }

  @Prop({
    type: String,
    required: true
  })
  public readonly uid!: string;

  @Prop({
    type: String,
    required: true
  })
  public readonly measurement!: string;

  @Prop({
    type: String,
    required: false,
    default: ''
  })
  public readonly unit!: string;

  @Watch('range')
  private onRangeChange(
    nVal: keyof typeof Durations,
    oVal: keyof typeof Durations
  ): void {
    if (nVal !== oVal) {
      this.getHistoryData();
    }
  }

  private formatYaxis(value: unknown): string {
    return `${
      typeof value === 'number'
        ? this.zoomedData
          ? value.toFixed(2)
          : value.toFixed(0)
        : value
    }${this.unit ? ` ${this.unit}` : ''}`;
  }

  private onChartClicked(): void {
    if (this.wasClicked) {
      this.wasClicked = false;
      this.zoomedData = null;
    } else {
      this.wasClicked = true;
      setTimeout((): void => void (this.wasClicked = false), 500);
    }
  }

  private onZoom(
    _chartContext: ApexCharts,
    opt: { xaxis: { min: number; max: number } }
  ): { xaxis: { min: number; max: number } } {
    const min: number = Math.floor(opt.xaxis.min);
    const max: number = Math.ceil(opt.xaxis.max);
    this.getHistoryData(min, max);
    return { xaxis: { min, max } };
  }

  private onResetZoom(): void {
    this.zoomedData = null;
  }

  private onDeleteHistory(): void {
    this.showDeleteHistory = true;
  }

  private mounted(): void {
    this.getHistoryData();
  }

  private getAggregateFromMilliseconds(duration: number): string | undefined {
    const aggregateMS: number = Math.floor(duration / 60);
    if (aggregateMS < 60 * 1000) {
      return undefined;
    }
    return `${aggregateMS}ms`;
  }

  private showError(message: string): void {
    Hub.dispatch('appAlert', {
      event: 'error',
      message
    });
  }

  private deleteHistoryData(): void {
    this.loading = true;
    this.showDeleteHistory = false;
    deleteHistoryData(this.uid, this.measurement)
      .then((): void => {
        this.data = {};
        this.zoomedData = null;
      })
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }

  private getHistoryData(start?: number, stop?: number): void {
    getHistoryData(
      this.uid,
      this.measurement,
      start || Durations[this.range].start,
      stop || 'now()',
      !!start && !!stop
        ? this.getAggregateFromMilliseconds(stop - start)
        : Durations[this.range].aggregate
    )
      .then((records: Record<number, unknown>): void => {
        if (!!start && !!stop) {
          if (Object.keys(records).length) {
            this.zoomedData = records;
          } else {
            this.zoomedData = {
              [start as number]: null,
              [stop as number]: null
            };
          }
        } else {
          this.data = records;
          this.zoomedData = null;
        }
      })
      .catch((error: Error): void => this.showError(error.message))
      .finally((): void => void (this.loading = false));
  }
}
